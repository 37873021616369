'use client';
import { usePathname } from 'next/navigation';
import LandingEventsCards from './LandingEventsCards';
import { BackgroundMask } from '../commons/BackgroundMask';
import FilterPopover from './FilterPopover';

const ExclusiveEventsCards = ({ opportunities = [] }) => {
  const pathname = usePathname();

  return (
    <BackgroundMask>
      <div className="pt-[60px] mb-4 md:my-0">
        <div className="layoutBox space-y-6 md:space-y-0">
          <div className="flex flex-col space-y-1  font-sans">
            <div className="relative flex justify-between items-center">
              <h1 className="md:text-[40px] text-lg font-medium flex-grow text-center">
                Hackingly Exclusive
              </h1>
              {pathname === '/event' && (
                <div className="absolute right-0">
                  <FilterPopover />
                </div>
              )}
            </div>
            <div className="md:pt-4 md:tracking-wide tracking-wide md:text-xl text-sm text-[#4F4F4F] text-center">
              Explore The Diverse Range Of Exclusive Events
            </div>
          </div>
          <LandingEventsCards opportunities={opportunities} />
        </div>
      </div>
    </BackgroundMask>
  );
};

export default ExclusiveEventsCards;
