import Image from 'next/image';
import Mask from '@/assets/images/backgroundimage.png';

export const BackgroundMask = ({ children }) => {
  return (
    <div className="relative">
      <Image
        src={Mask}
        width={1920}
        height={1080}
        alt="Mask"
        className="block absolute inset-0 w-full h-full object-cover z-0 select-none opacity-5"
      />
      {children}
    </div>
  );
};
