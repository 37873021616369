'use client';
import { useState } from 'react';
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  Button,
  Checkbox,
  Input,
} from '@nextui-org/react';
import Image from 'next/image';
import filter from '@/assets/svgs/filter.svg';
import { FaMagnifyingGlass } from 'react-icons/fa6';

const FilterPopover = () => {
  const [selectedFilters, setSelectedFilters] = useState(new Set([]));

  const handleSelectionChange = value => {
    setSelectedFilters(value);
  };

  const handleApplyFilters = () => {
    // Add your filter logic here
    console.log('Applied filters:', Array.from(selectedFilters));
  };

  const eventTypes = [
    'Hackathons',
    'Bootcamps',
    'Workshops',
    'Conferences',
    'Meetup',
  ];
  const modeOfEvents = ['Online', 'Offline'];
  const registerationEvents = ['Free', 'Paid'];

  return (
    <Popover placement="bottom">
      <PopoverTrigger>
        <Button
          className="md:px-8 bg-white text-black font-medium text-base lg:text-lg py-4"
          variant="bordered"
          size="lg"
          startContent={
            <Image src={filter} alt="filter" width={20} height={20} />
          }
        >
          FILTER
        </Button>
      </PopoverTrigger>

      <PopoverContent
        variant="bordered"
        className="p-6 space-y-4  lg:w-[450px]"
      >
        <div className="flex justify-between font-medium w-full text-lg ">
          <div className="text-lg">Search by Keywords</div>
          <button className="text-green-900">Clear</button>
        </div>
        {/* <div className='flex items-center gap-2'> */}
        <Input
          placeholder="Search.."
          size="lg"
          className="w-full text-lg "
          startContent={
            <FaMagnifyingGlass className="text-[#757575]" size={24} />
          }
        />
        {/* </div> */}
        <div className="flex flex-wrap space-y-3 w-full">
          <div className="flex justify-between w-full text-lg">
            <div>Event Types</div>
            <button className="text-green-900">Clear</button>
          </div>
          <div className="grid grid-cols-3 gap-3 w-full">
            {eventTypes.map(eventType => (
              <Button
                variant="bordered"
                radius="full"
                size="lg"
                className="text-sm w-full text-gray-500"
              >
                {eventType}
              </Button>
            ))}
          </div>
        </div>
        <div className="flex flex-wrap space-y-3 w-full">
          <div className="flex justify-between w-full text-lg">
            <div>Mode of Event</div>
            <button className="text-green-900">Clear</button>
          </div>
          <div className="grid grid-cols-3 gap-3 w-full">
            {modeOfEvents.map(modeOfEvent => (
              <Button
                variant="bordered"
                radius="full"
                size="lg"
                className="text-sm w-full text-gray-500"
              >
                {modeOfEvent}
              </Button>
            ))}
          </div>
        </div>
        <div className="flex flex-wrap space-y-3 w-full">
          <div className="flex justify-between w-full text-lg">
            <div>Registration Fee</div>
            <button className="text-green-900">Clear</button>
          </div>
          <div className="grid grid-cols-3 gap-3 w-full">
            {registerationEvents.map(registerationEvent => (
              <Button
                variant="bordered"
                radius="full"
                size="lg"
                className="text-sm w-full text-gray-500"
              >
                {registerationEvent}
              </Button>
            ))}
          </div>
        </div>
        <div className="flex justify-between w-full gap-4 py-4 mt-4">
          <Button
            variant="bordered"
            radius="3xl"
            size="lg"
            className="text-sm border-green-900 text-black text-lg flex-1"
          >
            Cancel
          </Button>
          <Button
            variant="ghost"
            radius="3xl"
            size="lg"
            color="none"
            className="text-sm border-green-900 text-black bg-[#097969] text-lg flex-1  text-white border-[#097969] border-1 shadow-inner-glow font-sans hover:bg-[#005D44] hover:shadow-inner-glow"
          >
            Apply Now
          </Button>
        </div>
      </PopoverContent>
    </Popover>
  );
};

export default FilterPopover;
