'use client';
import React from 'react';
import LandingEventsCards from './LandingEventsCards';
import { BackgroundMask } from '../commons/BackgroundMask';
const CommunityEventsCards = ({ opportunities = [] }) => {
  return (
    <BackgroundMask>
      <div className=" md:pt-[60px]">
        <div className=" layoutBox ">
          <div className="flex flex-col space-y-1 font-sans text-center md:mt-0">
            <h1 className=" md:text-[40px] text-lg font-medium text-center">
              Community Events
            </h1>
            <div className="md:pt-4 md:tracking-wide tracking-wide md:text-xl text-sm text-[#4F4F4F]">
              Bringing Our Community Together
            </div>
          </div>
          <LandingEventsCards opportunities={opportunities} />
        </div>
      </div>
    </BackgroundMask>
  );
};

export default CommunityEventsCards;
